








import { Component, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'

const Auth = namespace('auth')

@Component
export default class Home extends Vue {
  @Auth.Getter currentUser!: string
  @Auth.Getter isAuthenticated!: string

  mounted () {
    if (!this.isAuthenticated) {
      this.$router.push('/login')
    }
  }
}
